<script>
export default {
  data() {
    return {
    }
  },
  props: {
  },
  mounted: function () {
  },
  components: {
  },
  methods: {
    shareNavigator() {
      if(window.navigator.canShare) {
        const shareData = {
          title: "Busycast",
          text: "Podcast on the Go. Anytime, Anywhere.",
          url: "https://busycast.com",
        };
        try {
          navigator.share(shareData)
            .then(() => {
            })
            .catch(() => {
            });
        }
        catch(e) {
          console.log('Shared error', e);
        }
      }
    }
  },
  computed: {
    shareNavigatorAbility: function() {
      return window.navigator.canShare;
    }
  }
};
</script>

<template>
<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-8">
        {{new Date().getFullYear()}} © <b>busycast.com</b>, {{ $t("t-footer-author-madeby") }} <a href="https://koshovyi.com" target="_blank">{{ $t("t-footer-author-displayname") }}</a>.
        {{ $t("t-footer-pages-copyright") }} <router-link to="/pages/terms-conditions">{{ $t("t-footer-pages-terms") }}</router-link>, <router-link to="/pages/privacy-policy">{{ $t("t-footer-pages-privacy") }}</router-link>.
      </div>
      <div class="col-sm-4">
        <div class="text-sm-end d-none d-sm-block">
          <span class="me-2">{{ $t("t-footer-follow") }}</span>
          <a class="rounded-pill" href="https://twitter.com/busycast" target="_blank" rel="nofollow">
            <i class="ri-twitter-line label-icon align-middle rounded-pill fs-16 me-1"></i>
          </a>
          <a class="rounded-pill" href="https://www.instagram.com/busycast/" target="_blank" rel="nofollow">
            <i class="ri-instagram-line label-icon align-middle rounded-pill fs-16 me-1"></i>
          </a>
          <a class="rounded-pill" href="https://t.me/busycast" target="_blank" rel="nofollow">
            <i class="ri-telegram-line label-icon align-middle rounded-pill fs-16 me-1"></i>
          </a>
          <a class="rounded-pill" href="https://www.linkedin.com/company/busycast/" target="_blank" rel="nofollow">
            <i class="ri-linkedin-line label-icon align-middle rounded-pill fs-16 me-1"></i>
          </a>
          <a class="rounded-pill c-hand" @click="shareNavigator" v-if="shareNavigatorAbility">
            <i class="ri-share-line label-icon align-middle rounded-pill fs-16"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
</template>

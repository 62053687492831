<script>
export default {
  data() {
    return {
      search: ''
    }
  },
  components: {
  },
  mounted() {
  },
  methods: {
    preventEnterKey() {
      this.$router.push({ path: '/search', query: { q: this.search, t: 'episodes' } });
    },
    searchRedirect() {
    }
  },
  computed: {
  },
};
</script>

<template>
  <form class="app-search d-none d-md-block" v-on:submit.prevent="searchRedirect">
    <div class="position-relative">
      <input v-model="search" type="text" class="form-control" :placeholder="$t('t-header-search-placeholder')" autocomplete="off" v-on:keyup.enter="preventEnterKey">
      <span class="mdi mdi-magnify search-widget-icon"></span>
    </div>
  </form>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import RightBar from "@/components/RightBar.vue";
import Footer from "@/components/Footer.vue";

export default {
  methods: {
  },
  components: {
    NavBar,
    RightBar,
    Footer
  },
  watch:{
    $route() {
      if (document.documentElement.getAttribute("data-layout") === "horizontal") {
        if(document.body.classList.contains("menu"))
          document.body.classList.remove("menu");
      }
    }
} 
};
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <NavBar />

      <div class="app-menu navbar-menu">

        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="50" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt="" height="50" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt="" height="50" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt="" height="50" />
            </span>
          </router-link>
          <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
            <i class="ri-record-circle-line"></i>
          </button>
        </div>

        <div id="scrollbar">

          <div class="container-fluid">
            <ul class="navbar-nav h-100" id="navbar-nav">
              <li class="nav-item"></li>
              <li class="nav-item">
                <router-link to="/" class="nav-link menu-link" active-class="active">
                  <i class="ri-home-line"></i>
                  {{ $t("t-header-menu-home") }}
                </router-link>
              </li>

              <li class="nav-item">
                <router-link to="/podcasts" class="nav-link menu-link">
                  <i class="ri-album-line"></i>
                  {{ $t("t-header-menu-podcasts") }}
                </router-link>
              </li>

              <li class="nav-item">
                <router-link to="/episodes" class="nav-link menu-link">
                  <i class="ri-music-2-line"></i>
                  {{ $t("t-header-menu-episodes") }}
                </router-link>
              </li>

              <li class="nav-item">
                <router-link to="/profiles" class="nav-link menu-link">
                  <i class="ri-group-line"></i>
                  {{ $t("t-header-menu-profiles") }}
                </router-link>
              </li>

              <!-- <li class="nav-item">
                <router-link :to="{ name: 'blog', params: { blogLink: $t('t-header-menu-blog-link') } }"
                  class="nav-link menu-link">
                  <i class="ri-newspaper-line"></i>
                  {{ $t("t-header-menu-blog") }}
                </router-link>
              </li> -->

              <li class="nav-item">
                <router-link to="/faq" class="nav-link menu-link">
                  <i class="ri-question-line"></i>
                  {{ $t("t-header-menu-faq") }}
                </router-link>
              </li>

              <!-- <li class="nav-item">
                <router-link to="/donate" class="nav-link menu-link">
                  <i class="ri-service-line"></i>
                  Donate
                </router-link>
              </li> -->

              <li class="nav-item">
                <router-link to="/contacts" class="nav-link menu-link">
                  <i class="ri-mail-line"></i>
                  {{ $t("t-header-menu-contacts") }}
                </router-link>
              </li>

            </ul>
          </div>
        </div>

        <div class="vertical-overlay"></div>
      </div>

      <div class="main-content mt-4">

        <div class="page-content">
          <div class="container-fluid">
            <slot />
          </div>
        </div>

        <Footer />

      </div>
      <RightBar />
    </div>
  </div>
</template>